import React from 'react';
import loadable from '@loadable/component';
import styles from './ProductSliderSection.module.scss';
import PageSectionHeader from '../PageSectionHeader';
import { useSliderScroll } from '../Sliders/useSliderScroll';
import { useHistory } from 'react-router-dom';
import { VIEW_OPTIONS_TILES } from '../SearchResultsPage/SRP.definitions';
import getCDNImage from '../../utils/getCDNImage';
import { SLIDER_CUSTOM_NAV } from '../Sliders/Sliders.def';
import classNames from 'classnames';

const Sliders = loadable(() => import('Components/Sliders/Sliders'));
const Product = loadable(() => import('../Product/Product'));

const ProductSliderSection = ({ products = [], classList, title, productCardOriginType }) => {
  const {
    onScrollForward,
    onScrollBack,
    scrollLeft,
    isScrolledToEnd,
    sliderContainerRef,
    onScroll,
  } = useSliderScroll();
  const { push } = useHistory();

  return (
    <section className={classList?.root || styles.root}>
      <PageSectionHeader
        classList={{ title: classList?.title, root: classList?.sectionHeader || styles.sectionHeader }}
        withSliderControls
        sliderNext={onScrollForward}
        sliderPrevious={onScrollBack}
      >
        {title}
      </PageSectionHeader>
      <div className={classNames('container')}>
        <Sliders
          classList={{ root: styles.slidersRoot, slides: styles.slidesWrapper, card: styles.productCardWrapper }}
          type={SLIDER_CUSTOM_NAV}
          scrollLeft={scrollLeft}
          isScrolledToEnd={isScrolledToEnd}
          sliderContainerRef={sliderContainerRef}
          viewAll={false}
          onScroll={onScroll}
        >
          {products.map((product, index) => (
            <Product
              adult={product?.adult}
              key={`pssitem${product.id}`}
              brand={product?.brand}
              classList={{ root: styles.productCard }}
              currency={product?.currency || process.env.REACT_APP_WEBSITE_CURRENCY}
              shortDesc={product?.shortDesc}
              EAN={product?.offers?.[0]?.EAN}
              price={product.price}
              originalPrice={product.price_original || product.price}
              id={product.id}
              image={getCDNImage(product?.thumbs?.[0])}
              isSelected={false}
              name={product.name}
              onClick={() => push(product.pdp_path)}
              shop={{ name: product.campaign }}
              SKU={product.SKU}
              showProductLink={false}
              url={product.pdp_path}
              view={VIEW_OPTIONS_TILES}
              status={product.new}
              shopId={product?.shop_id}
              merchantPid={product?.merchant_product_id}
              resultRank={index}
              category={product?.category}
              productCardOriginType={productCardOriginType}
            />
          ))}
        </Sliders>
      </div>
    </section>
  );
};

export default ProductSliderSection;
